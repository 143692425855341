<template>
    <Toolbar>
        <template #left>
            <div class="flex align-items-center justify-content-center">
                <span class="text-lg  font-semibold">Plan Didáctico </span>
                <Breadcrumb  :home="null" :model="breadcrumbModel" class="hidden md:block"/>
            </div>
        </template>

        <template #right>
            <Button type="button" label="Filtro"  icon="pi pi-filter" class="p-button-info btn-light-primary mr-2" @click="toggle" />
            <Button type="button" label="Nuevo"   @click="newPlan_onClick" />
            <OverlayPanel ref="filterOP" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '320px'}">
                <h5 class="font-semibold">Filtro</h5>
                <Divider />
                <!--Field : Facultad -->
                <div class="field">
                    <label for="fac_codigo" >Facultad</label>
                    <Dropdown  id="fac_codigo"  v-model="selectedFacultad" :options="facultadList"   placeholder="Seleccione Facultad" :showClear="true"  class="w-full"  @change="selFacultad_onChange($event)" >
                         <template #value="slotProps">
                            <div  v-if="slotProps.value">
                                <span>{{slotProps.value.fac_nombre}}</span>
                            </div>
                            <span v-else>
                                {{slotProps.placeholder}}
                            </span>
                        </template>
                        <template #option="slotProps">
                            <span>{{slotProps.option.fac_nombre}}</span>
                        </template> 
                    </Dropdown>
                </div>
                <!-- Field : Carrera -->
                <div class="field">
                     <label for="car_codigo">Carrera</label>
                    <Dropdown id="car_codigo" v-model="selectedCarrera" :options="carreraList" optionLabel="car_nombre" :filter="true" placeholder="Seleccione Carrera" :showClear="true" class="w-full" :loading="loadingCarrera">
                        <template #empty>
                            <div class="">Seleccione Facultad </div>
                        </template>
                        <template #value="slotProps">
                            <div  v-if="slotProps.value">
                                <div>{{slotProps.value.car_nombre}}</div>
                            </div>
                            <span v-else>
                                {{slotProps.placeholder}}
                            </span>
                        </template>
                        <template #option="slotProps">
                            <div>
                                <div>{{slotProps.option.car_nombre}}</div>
                            </div>
                        </template>
                    </Dropdown>
                </div>
                <div class="field">
                     <label for="cil_codigo">Ciclo</label>
                    <Dropdown  id="cil_codigo"  v-model="selectedCiclo" :options="cicloList" placeholder="Seleccione Ciclo" class="w-full" >
                        <template #empty>
                            Opciones no válida
                        </template>
                        <template #value="slotProps">
                            <div  v-if="slotProps.value">
                                <span>{{slotProps.value.cil_descripcion}}</span>
                            </div>
                            <span v-else>
                                {{slotProps.placeholder}}
                            </span>
                        </template>
                        <template #option="slotProps">
                            <span>{{slotProps.option.cil_descripcion}}</span>
                        </template> 
                    </Dropdown>
                </div>
                <Divider />
                <div class="flex justify-content-end">
                    
                    <div class="flex">
                        <Button label="Cancelar" icon="pi pi-times"  class="p-button-text  p-button-danger mr-2 text-xs " @click="toggle"/>
                        <Button label="Aceptar" icon="pi pi-check" class="text-xs p-1"  @click="apply_onClick()"  />
                    </div>
                </div> 
            </OverlayPanel>
        </template>

    </Toolbar>
   
    <div class="grid mt-1">
       
		<div class="col-12" :class="{'flex justify-content-center': !viewPlanTable }">
			<Card :class="initClass">
                 <template #content>
                      <!--Begin: Loader /Waiter -->
                      <!-- Loader indicator -->
                    <div class="flex  flex-column align-items-center text-center"  v-if="!viewPlanTable" >
                        <h3 class="font-semibold text-gray-900">PLANIFICACIÓN DIDÁCTICA</h3>
                        <p class="text-gray-500  font-semibold mb-3">
                            <span>Click en el botón filtro <br>Para cargar todas las planificaciones didácticas del sistema.</span>
                    </p>
                    <div class="text-center">
                        <img src="@/assets/layout/images/logo-vertical.png" class="w-22rem mb-2"/>
                        <ProgressBar mode="indeterminate" style="height: .5em" />
                    </div>
                    </div>
                    <!--End: Loader/Waiter -->

                    <!-- Content Table Result -->
                    <PlanDidacticoData v-else 
                        :Service="planDidacticoService" 
                        :Facultad="selectedFacultad" 
                        :Carrera="selectedCarrera"
                        :Ciclo="selectedCiclo"
                        :key="planKey"/>
                 </template>
            </Card>
        </div>
       
    </div>

    <!-- Begin: Dialogs -->
    <PlanDidacticoCreate  :isVisible="isDialogCreate"  @cancel_onClick="isDialogCreate = false;" />
    
</template>

<script>

import FacultadService from "@/service/facultadService"
import PlanDidacticoService from '@/service/planDidacticoService'
import GeneralService from '@/service/generalService'


import PlanDidacticoCreate from "@/components/LaborDocente/Planificacion/PlanDidacticoCreate" 
import PlanDidacticoData from "@/components/LaborDocente/Planificacion/PlanDidacticoData" 

//waiting indicator 
const delay = ms => new Promise(res => setTimeout(res, ms)); 

export default {
    
    facultadService : null,
    planDidacticoService :  null,
    data(){
        return {
            facultadList : 0,
            selectedFacultad : null,
            carreraList : [],
            cicloList: [],
            selectedCiclo: null,

            selectedCarrera : null,
            loadingCarrera : true,

            viewPlanTable  : false,
            isDialogCreate : false,

            breadcrumbModel :[],
            planKey : 0
        }
    },
    created(){
        this.facultadService =  new FacultadService(this.axios);
        this.planDidacticoService =  new PlanDidacticoService(this.axios);
        this.generalService =  new GeneralService(this.axios)

        this.breadcrumbModel = [ 
            { label: this.$store.state.uConfig.cfCurrentModule.mod_nombre, to: '#'},
            { label: "Labor Docente", to: '#'},
            { label: this.$store.state.uConfig.cfCurrentMenu.men_descripcion, to: '#'},
        ]
    },
    async mounted(){
        await delay(1500);
        try{
            
            await this.facultadService.getAllFacultad().then(data  => this.facultadList = data);
            await this.generalService.getCiclo().then(result => this.cicloList  =  result )
            
            await this.fxInit();
        }
        catch(ex){
            this.$catchError(ex)
        }
    },

    methods:{

        async fxInit(){
            //Buscar si pertenece especificamente a una facultad para limitar
            var fac  =  this.facultadList.filter(x=> x.fac_codigo == this.$store.state.uEmpleado.emp_codfac);

            // Si esta autorizado para ver todos y tiene asignada facultad reestringir facultad
            if(fac.length > 0 && this.$store.getters.evalAuth("VerTodos")){
                this.selectedFacultad =  fac[0];
                this.facultadList =  fac;
                await this.selFacultad_onChange({ value :  { fac_codigo :  this.selectedFacultad.fac_codigo} })
            }else{
                this.facultadList.push({fac_codigo: 0 , fac_nombre: "(Mis Asignaciones)" , fac_nombre_corto : "T"})
                this.selectedFacultad =  this.facultadList[this.facultadList.length - 1];
            }
            
           
            //Selected Ciclo Vigente
            if(this.cicloList.length > 0 ){
                var vigente =  this.cicloList.findIndex(x=> x.cil_vigente =="S");
                this.selectedCiclo =  vigente <= -1 ? null : this.cicloList[vigente]
            }

            
            //Ver autorizaciones de filtro en componentes
            this.viewPlanTable =  true;
        },
        
        toggle(event) { // OverlayPanel
            this.$refs.filterOP.toggle(event);
        },

        async selFacultad_onChange(event){
            this.selectedCarrera = null;
            this.loadingCarrera = true;
            if(!event.value){
                return;
            }
            await this.facultadService.getCarreraByFacultad(event.value.fac_codigo).then(data => this.carreraList = data)
            this.loadingCarrera = false;
        },
        
        async apply_onClick(){ //Aplicar Filtro OverlayPanel
            this.$refs.filterOP.toggle();
            //this.$refs.planTable.fxPlanificacion_Load(1,10,"")
            this.planKey++
        },
        async newPlan_onClick(){
            if(!this.$store.getters.evalAuth("btnNew", this.$toast)){
                return;
            }
            this.isDialogCreate =  true;
        }
    },

    computed:{
        initClass(){
            if(!this.viewPlanTable)
                return "lg:w-9 xl:w-6 w-full  border-noround"
            else 
                return ""
        }
    },
    components:{
        PlanDidacticoCreate,
        PlanDidacticoData,
    }
}
</script>
