<template>
     <!--Component : Agregar nueva planificacón -->
    <Dialog v-model:visible="createVisible" :style="{width: '640px'}" :modal="true" class="p-fluid" @hide= "hide_event"  :closeOnEscape="false">
        <template #header>
            <div class="flex flex-column align-items-center w-full">
                <h4 class="font-semibold text-gray-900 mb-0 pb-0">Planificación Didáctica</h4>
                <small  class="text-muted text-base  font-semibold text-center">Crear nueva planificación didáctica</small>
            </div>
        </template>
         <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
            <div class="field">
                <label for="fac_codigo" :class="{'p-error':v$.selectedFacultad.$invalid && submitted}">Facultad</label>
                <Dropdown  id="fac_codigo"  v-model="selectedFacultad" :options="facultadList"   placeholder="Seleccione Facultad" class="w-full"  
                    @change="selFacultad_onChange($event)" :class="{'p-invalid':v$.selectedFacultad.$invalid && submitted}">
                    <template #value="slotProps">
                        <div  v-if="slotProps.value">
                            <span>{{slotProps.value.fac_nombre}}</span>
                        </div>
                        <span v-else>
                            {{slotProps.placeholder}}
                        </span>
                    </template>
                    <template #option="slotProps">
                        <span>{{slotProps.option.fac_nombre}}</span>
                    </template> 
                </Dropdown>
                <small v-if="(v$.selectedFacultad.$invalid && submitted) || v$.selectedFacultad.$pending.$response" class="p-error">{{v$.selectedFacultad.required.$message}}</small>
            </div>

            <div class="field">
                <label for="car_codigo" :class="{'p-error':v$.selectedCarrera.$invalid && submitted}">Carrera</label>
                <Dropdown id="car_codigo" v-model="selectedCarrera" :options="carreraList" optionLabel="car_nombre" :class="{'p-invalid':v$.selectedCarrera.$invalid && submitted}"
                    :filter="true" placeholder="Seleccione Carrera" :showClear="true" class="w-full" :loading="loadingCarrera"  @change="selCarrera_onChange($event)">
                    <template #empty>
                        <div class="">Seleccione Facultad </div>
                    </template>
                    <template #value="slotProps">
                        <div  v-if="slotProps.value">
                            <div>{{slotProps.value.car_nombre}}</div>
                        </div>
                        <span v-else>
                            {{slotProps.placeholder}}
                        </span>
                    </template>
                    <template #option="slotProps">
                        <div>
                            <div>{{slotProps.option.car_nombre}}</div>
                        </div>
                    </template>
                </Dropdown>
                <small v-if="(v$.selectedCarrera.$invalid && submitted) || v$.selectedCarrera.$pending.$response" class="p-error">{{v$.selectedCarrera.required.$message}}</small>
            </div>
            <div class="formgrid grid">
                <div class="field col-3">
                    <label for="pla_codigo" :class="{'p-error':v$.selectedPlanAcademico.$invalid && submitted}" >Plan</label>
                    <Dropdown id="pla_codigo" v-model="selectedPlanAcademico" :options="planAcademicoList" optionLabel="pla_nombre" :class="{'p-invalid':v$.selectedPlanAcademico.$invalid && submitted}" 
                        :filter="true" placeholder="Plan" :showClear="true" class="w-full" :loading="loadingPlan" @change="selPlanAcad_onChange($event)">
                        <template #empty>
                            <div class="">No hay planes asociados </div>
                        </template>
                        <template #value="slotProps">
                            <div  v-if="slotProps.value">
                                <div>{{slotProps.value.pla_nombre}}</div>
                            </div>
                            <span v-else>
                                {{slotProps.placeholder}}
                            </span>
                        </template>
                        <template #option="slotProps">
                            <div>
                                <div class="ml-1">{{slotProps.option.pla_nombre}}</div>
                            </div>
                        </template>
                    </Dropdown>
                    <small v-if="(v$.selectedPlanAcademico.$invalid && submitted) || v$.selectedPlanAcademico.$pending.$response" class="p-error">{{v$.selectedPlanAcademico.required.$message}}</small>
                </div>
                <div class="field col">
                    <label for="plm_codmat" :class="{'p-error':v$.selectedAsignatura.$invalid && submitted}">Asignatura </label>
                    <Dropdown id="plm_codmat" v-model="selectedAsignatura" :options="asignaturaList" optionLabel="plm_mat_nombre" :filter="true" placeholder="Seleccione Asignatura"  
                    class="w-full" :loading="loadingAsignatura" :class="{'p-invalid':v$.selectedAsignatura.$invalid && submitted}">
                        <template #empty>
                            <div class="">Seleccione Facultad </div>
                        </template>
                        <template #value="slotProps">
                            <div  v-if="slotProps.value">
                                <div>{{slotProps.value.plm_mat_nombre}}</div>
                            </div>
                            <span v-else>
                                {{slotProps.placeholder}}
                            </span>
                        </template>
                        <template #option="slotProps">
                            <div>
                                <div class="ml-1">{{slotProps.option.plm_mat_nombre}}</div>
                            </div>
                        </template>
                    </Dropdown>
                    <small v-if="(v$.selectedAsignatura.$invalid && submitted) || v$.selectedAsignatura.$pending.$response" class="p-error">{{v$.selectedAsignatura.required.$message}}</small>
                </div>
            </div>

            <div class="formgird grid">
                <div class="field col-6">
                    <label for="fac_codigo" :class="{'p-error':v$.selectedCiclo.$invalid && submitted}">* Ciclo</label>
                    <Dropdown  id="fac_codigo"  v-model="selectedCiclo" :options="cicloList"   placeholder="Seleccione Ciclo" :class="{'p-invalid':v$.selectedCiclo.$invalid && submitted}">
                            <template #value="slotProps">
                            <div  v-if="slotProps.value">
                                <span>{{slotProps.value.cil_descripcion}}</span>
                            </div>
                            <span v-else>
                                {{slotProps.placeholder}}
                            </span>
                        </template>
                        <template #option="slotProps">
                            <span>{{slotProps.option.cil_descripcion}}</span>
                        </template> 
                    </Dropdown>
                    <small v-if="(v$.selectedCiclo.$invalid && submitted) || v$.selectedCiclo.$pending.$response" class="p-error">{{v$.selectedCiclo.required.$message}}</small>
                </div>
                <div class="field col-3">
                    <label for="pla_horas_ens" :class="{'p-error':v$.horas_ens.$invalid && submitted}" >Horas Enseñanza</label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-clock text-primary"></i>
                        </span>
                       <InputText for="pla_horas_ens" v-model="horas_ens"  :class="{'p-invalid':v$.horas_ens.$invalid && submitted}" class="text-right"/>
                    </div>
                </div>
                <div class="field col-3">
                    <label for="pla_horas_apren" :class="{'p-error':v$.horas_apren.$invalid && submitted}" >Horas Aprendizaje</label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon ">
                            <i class="pi pi-clock text-primary"></i>
                        </span>
                        <InputText for="pla_horas_apren" v-model="horas_apren" :class="{'p-invalid':v$.horas_apren.$invalid && submitted}" class="text-right"/>
                    </div>
                   
                </div>
            </div>

            <div class="flex flex-column">
                <div class="flex border-round bg-light-warning p-4 mt-2 border-dashed-custom  border-warning mb-3 "> 
                    <div class="block">
                        <a href="#" class="font-bold mx-1 text-danger">COMISIÓN </a> 
                        Docentes que crearán colaborativamente, validarán y aplicarán esta planificación
                        <a href="javascript:void(0)" class="font-bold ml-1 text-danger" @click="openDialogEmpleado_onClick()"> <i class="pi pi-plus-circle"/> Agregar</a>
                    </div> 
                </div>
                <div class="grid">
                    <div class="col-12 sm:col-12 md:col-6 lg:col-6" v-for="com,index in comisionList" :key="index">
                        <div class="mb-1 flex align-items-center">
                            <div class="mr-1"></div>
                            <div class="flex-grow-1">
                                <a href="#" class="text-gray-900 font-medium  hover:text-primary ">{{com.emp_nombres_apellidos}}</a>
                                <span class="text-gray-500 font-medium block"> {{com.emp_puesto}}</span>
                            </div>
                            <a href="javascript:void(0)" class="btn btn-bg-light-danger p-2 mr-2" v-tooltip.top="'Eliminar'" :key="index"  @click="deleteComision_onClick(com)" > 
                                <i class="pi pi-trash text-danger"></i>
                            </a>
                        </div>
                    </div>
                </div> 
            </div>
            <Button type="submit" label="Generar" class="mt-2" :loading="loadingCreate"/>
        </form>

        <template #footer>
            <div class="flex justify-content-end">
                <Button label="Cancelar" icon="pi pi-times" class="p-button-danger p-button-text" @click="cancel_onClick"/>
            </div>
            <Divider/>
            <div class="flex mt-5">
                <div class="block font-bold text-left">
                    <label class="">Creando un plan didáctica</label>
                    <div class="text-sm text-gray-400">Las planificaciones corresponden a un ciclo académico, una asignatura y un plan académico.</div>
                </div>
            </div>
        </template> 
    </Dialog>

    <!--Begin : Empleado Dialog -->
    <Dialog v-model:visible="isDialogEmpleado" :breakpoints="{'1360px': '95vw'}" :style="{width: '50vw'}" :modal="true">
        <template #header>
           <div class="flex flex-column align-items-center w-full">
                <h4 class="font-semibold text-gray-900 mb-0 pb-0">Buscar empleado</h4>
                <small  class="text-muted text-base  font-semibold text-center">Agregar empleado a un plan didáctico</small>
            </div>
        </template>
        <EmpleadoDataTable  v-model:selectedItems="resultSelectedEmpleado"> </EmpleadoDataTable>
        <template #footer>
            <Divider  class="mt-0 py-0"/>
            <div class="flex justify-content-end">
                <Button label="Cancelar" icon="pi pi-times"  class="p-button-danger p-button-text " @click="cancelComision_onClick()"/>
                <Button label="Aceptar" icon="pi pi-check" class=""  @click="selectedComision_onClick()"  />
            </div>
        </template>
    </Dialog>
    <!-- End : Empleado Dialog -->
</template>

<script>
import FacultadService from '@/service/facultadService'
import GeneralService from '@/service/generalService'
import PlanDidacticoService from '@/service/planDidacticoService'

import EmpleadoDataTable from "../../Generales/EmpleadoDt"

//Library
import { required,helpers  } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import methods from "@/utilities/generalMethods.js"

//SVG
export default {
    
    props: {
        isVisible :{
            type : Boolean,
            default :  false
        }, // Visible
    },
    emits : ["update:isVisible", 'cancel_onClick','save_onClick'],
    facultadService : null,
    generalService  : null,
    planDidacticoService :  null,
    setup: () => ({ v$: useVuelidate() }),
    data(){
        return{
            cicloList : [],
            selectedCiclo : null,

            facultadList : [],
            selectedFacultad : null,

            carreraList : [],
            selectedCarrera : null,

            asignaturaList : [],
            selectedAsignatura : null,

            planAcademicoList : [],
            selectedPlanAcademico : null,

            horas_ens : 0,
            horas_apren: 0,
            comisionList : [],
            resultSelectedEmpleado : [],

            pld_problematica : "",
            pld_com_asignatura : "",

            loadingCarrera : true,
            loadingAsignatura : true,
            loadingPlan : true,

            createVisible :  this.isVisible, //Modal show/hide
            loadingCreate : false, 
            isDialogEmpleado : false, // Modal Show/Hide
            submitted : false
        }
    },
    validations() {
        return {
            selectedCiclo: {
                required:  helpers.withMessage(
                    () => `* Seleccione un ciclo válido`,
                    required)
            },
            selectedFacultad: {
                required:  helpers.withMessage(
                        () => `* Seleccione una facultad válida`,
                        required
                    )
            },
            
            selectedCarrera: {
                required:  helpers.withMessage(
                        () => `* Seleccione una carrera válida`,
                        required
                    )
            },
            selectedPlanAcademico: {
                required:  helpers.withMessage(
                        () => `* No válido`,
                        required
                    )
            },
            selectedAsignatura: {
                required:  helpers.withMessage(
                        () => `* No válido`,
                        required
                    )
            },

            horas_ens: {
                required : helpers.withMessage(
                    () => `*Campo requerido`,
                    required
                ),
            },
            horas_apren: {
                required : helpers.withMessage(
                    () => `*Campo requerido`,
                    required
                ),
            },
        }
    },
    created(){
        this.facultadService =  new FacultadService(this.axios)
        this.generalService =  new GeneralService(this.axios)
        this.planDidacticoService =  new PlanDidacticoService(this.axios) 
    },
    async mounted(){
        try{
            await this.facultadService.getAllFacultad().then(result => {
                this.facultadList =  result
                var fac  =  this.facultadList.filter(x=> x.fac_codigo == this.$store.state.uEmpleado.emp_codfac);
                if(fac.length > 0){
                    this.selectedFacultad =  fac[0];
                    this.facultadList =  fac;
                    this.selFacultad_onChange({ value :  { fac_codigo :  this.selectedFacultad.fac_codigo} })
                }
            })
            await this.generalService.getCiclo().then(result => this.cicloList  =  result )
        }catch(ex){
            var msg = methods.messageCode(ex)
            this.$toast.add({severity:'warn', summary:'¡Ocurrió un error!', detail:msg, life: 5000});
            this.$catchError(ex)
        }
    },
    methods:{
        hide_event(){
            this.$emit('cancel_onClick')
        },

        cancel_onClick(){
            this.$emit('update:isVisible', !this.isVisible)
            this.$emit('cancel_onClick')
            this.createVisible =  false;
        },
        async handleSubmit(isFormValid) {

            try{
                this.submitted = true;
                if (!isFormValid) {
                    return;
                }

                //Restrinción Extra.
                var indexVigente =  this.cicloList.findIndex(x=> x.cil_vigente =="S");
                var cicloVigente =  indexVigente <= -1 ? null : this.cicloList[indexVigente] 
                if(this.selectedCiclo.cil_codigo < cicloVigente.cil_codigo)
                {
                    throw `El ciclo de plan didáctico a crear "${this.selectedCiclo.cil_descripcion}"  no puede ser menor al ciclo vigente "${cicloVigente.cil_descripcion}"`
                }

                this.loadingCreate = true;
                await new Promise(resolve => {
                    setTimeout(async () => {
                        this.loadingCreate = false;
                        await this.planDidacticoService.createPlan(
                        {
                            codplm: this.selectedAsignatura.plm_codigo,
                            codcil: this.selectedCiclo.cil_codigo,
                            ciclo: this.selectedCiclo.cil_descripcion,
                            fechacil: this.selectedCiclo.cil_fecha_ini,
                            problematica: this.pld_problematica,
                            comp_asignatura: this.pld_com_asignatura,
                            horas_ens: this.horas_ens,
                            horas_apren: this.horas_apren
                            }).then(result=> {
                                this.planDidacticoService.createComision(result.codigo,this.comisionList).then(res=> res);
                                
                                if(result){
                                    this.$router.push({
                                        name: "PlaneacionDetalle",
                                        params:{
                                            id: result.codigo,
                                        }
                                    });
                                }
                        });
                        resolve();
                    }, 1000);
                    });

                this.$emit('save_onClick')
                this.createVisible =  false;
            }catch(ex){
                var msg = methods.messageCode(ex)
                this.$toast.add({severity:'warn', summary:'¡Ocurrió un error!', detail:msg, life: 5000});
            }
        },

        async selFacultad_onChange(event){
            this.selectedCarrera = null;
            this.selectedAsignatura = null;
            this.loadingCarrera = true;
            await this.facultadService.getCarreraByFacultad(event.value.fac_codigo).then(data => this.carreraList = data)
            this.loadingCarrera = false;
        },

        async selCarrera_onChange(event){

            this.selectedPlanAcademico = null;
            this.loadingPlan = true;

            if(event.value === null){
                this.planAcademicoList = [];
                return;
            }
            await this.facultadService.getPlanByCarrera(event.value.car_codigo).then(result => this.planAcademicoList =  result)
            this.loadingPlan = false;

        },

        async selPlanAcad_onChange(event){
            this.selectedAsignatura = null;
            this.loadingAsignatura = true;

            if(event.value === null){
                this.asignaturaList = [];
                return;
            }

            await this.facultadService.getAsignaturaByPlanAcad(event.value.pla_codigo).then(result => this.asignaturaList =  result)
            this.loadingAsignatura = false;
        },

        /*#region  Searching Comision*/
        async openDialogEmpleado_onClick(){
            this.isDialogEmpleado = true
            this.resultSelectedEmpleado =  this.comisionList;
        },

        async selectedComision_onClick(){
            this.isDialogEmpleado =  false;
            this.comisionList =  this.resultSelectedEmpleado;
        },

        async cancelComision_onClick(){
            this.isDialogEmpleado = false;
            this.resultSelectedEmpleado =  [];
        },
        deleteComision_onClick(com){
            var index = this.comisionList.findIndex(x=> x.emp_codigo == com.emp_codigo);
            if(index <= -1) return;
            this.comisionList.splice(index,1);
        }
        /*#endregion:  Searching Comision*/
    } ,
    updated() {
        if (this.isVisible) {
            this.createVisible = this.isVisible;
        }
    },

    components:{
        "EmpleadoDataTable" :  EmpleadoDataTable
    }
}
</script>
